import React from 'react'
import { graphql } from 'gatsby'

import Img from 'gatsby-image'
import Layout from '../components/layout'
import SEO from '../components/seo'



class IndexPage extends React.Component {
  render() {

    const { data } = this.props;
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout>
        <SEO title="Home" keywords={[`paris`]} />


        {posts.map(({ node }) => {
          return (
            <article key={node.frontmatter.title}>
              <header>
                <h1>{node.frontmatter.title}</h1>
                <p>posted on <time dateTime="{node.frontmatter.date}">{node.frontmatter.date}</time> by {node.frontmatter.author}</p>
              </header>
                <Img fluid={node.frontmatter.featuredImage.childImageSharp.fluid} />
              <div dangerouslySetInnerHTML={{ __html: node.html }} />
              </article>
            )
          })}
      </Layout>
    )
  }
}

export default IndexPage

export const pageQuery = graphql`
query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: ASC }, filter: { frontmatter:  { type: { eq:"post"}}}) {
      edges {
        node {
          excerpt
          frontmatter {
            title
            type
            date(formatString: "MMMM DD, YYYY")
            author
            featuredImage{
              childImageSharp {
                fluid(maxWidth: 300) {
                    ...GatsbyImageSharpFluid
                }
              }
            }
            alt
          }
          html
        }
      }
    }
  }
`
